import {
  UserSignedInAction,
  UserSignedInPayload,
  USER_SIGNED_IN
} from "./types";

export const userSignedIn = (
  payload: UserSignedInPayload
): UserSignedInAction => ({
  type: USER_SIGNED_IN,
  payload
});
