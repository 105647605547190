import {
  FETCH_PROFILES,
  FETCH_PROFILES_SUCCESS,
  FETCH_PROFILES_ERROR,
  FetchProfilesAction,
  FetchProfilesSuccessAction,
  FetchProfilesErrorAction,
  Profile
} from "./types";

export const fetchProfiles = (): FetchProfilesAction => ({
  type: FETCH_PROFILES
});

export const fetchProfilesSuccess = (
  profiles: Profile[]
): FetchProfilesSuccessAction => ({
  type: FETCH_PROFILES_SUCCESS,
  payload: profiles
});

export const fetchProfilesError = (): FetchProfilesErrorAction => ({
  type: FETCH_PROFILES_ERROR
});
