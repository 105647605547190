import { combineReducers } from "redux";
import profiles from "./profiles/reducer";
import user from "./user/reducer";

const rootReducer = combineReducers({
  profiles,
  user
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
