import React from "react";
import getAppRoutes from "../../components/AppRoutes";
import { Switch, withRouter } from "react-router-dom";

const Routes = () => {
  const appRoutes = getAppRoutes();
  return (
    <div>
      <Switch>
        {appRoutes.map((Route, i) => {
          return React.cloneElement(Route, { key: `@appRoutes/${i}` });
        })}
      </Switch>
    </div>
  );
};

export default withRouter(Routes);
