import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { NavLink as RouterLink } from "react-router-dom";
import AppBar from "../../components/AppBar";
import Routes from "../Routes/Routes";
import Hidden from "@material-ui/core/Hidden";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    toolbar: theme.mixins.toolbar as CSSProperties
  })
);

const AppLayout = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const routes = [
    { title: "Home", to: "/", exact: true },
    { title: "Search", to: "/search" },
    { title: "Profiles", to: "/profiles" },
    { title: "Audits", to: "/audits" }
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        {routes.map(text => (
          <ListItem
            component={RouterLink}
            to={text.to}
            key={text.title}
            activeClassName="Mui-selected"
            exact={text.exact}
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={text.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar onToggle={handleDrawerToggle} />

      <Hidden smUp implementation="js">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="js">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          open
        >
          {drawer}
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes />
      </main>
    </div>
  );
};

export default AppLayout;
