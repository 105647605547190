import React from "react";
import Loadable from "react-loadable";
import { Typography } from "@material-ui/core";

const MyLoadable = (opts: any) => {
  return Loadable({
    ...opts,
    loading: () => <Typography variant="h5">Loading...</Typography>
  });
};

export const AsyncPageNotFound = MyLoadable({
  loader: () => import("../../pages/PageNotFound")
});

export const AsyncHome = MyLoadable({
  loader: () => import("../../pages/Home")
});

export const AsyncSearch = MyLoadable({
  loader: () => import("../../pages/Search")
});

export const AsyncAudits = MyLoadable({
  loader: () => import("../../pages/Audits")
});

export const AsyncProfiles = MyLoadable({
  loader: () => import("../../pages/Profiles")
});
