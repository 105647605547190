import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Auth from "@creditsafe/upp-authentication";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.modal + 1
    },
    title: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    }
  })
);

interface AppBarProps {
  onToggle(): void;
}

const AppBar: React.FC<AppBarProps> = ({ onToggle }) => {
  const classes = useStyles();
  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap className={classes.title}>
          Compliance
        </Typography>
        <Button color="inherit" onClick={() => Auth.signOut()}>
          Signout
        </Button>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
