import { ofType, Epic } from "redux-observable";
import {
  FETCH_PROFILES,
  FetchProfilesSuccessAction,
  FetchProfilesErrorAction,
  Profile
} from "./types";
import { of } from "rxjs";
import { AjaxResponse, AjaxError } from "rxjs/ajax";
import { map, switchMap, catchError } from "rxjs/operators";
import { fetchProfilesSuccess, fetchProfilesError } from "./actions";
import { EpicDependencies } from "../epicDependencies";
import { ActionTypes } from "../types";

export const fetchProfilesEpic: Epic<
  ActionTypes,
  FetchProfilesSuccessAction | FetchProfilesErrorAction,
  any,
  EpicDependencies
> = (action$, _$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_PROFILES),
    switchMap(() =>
      ajax(
        "http://compl-compl-1a0w8kkjlon59-1392447855.eu-west-1.elb.amazonaws.com/v1/profiles"
      ).pipe(
        map((response: AjaxResponse) => response.response),
        map((profiles: Profile[]) => fetchProfilesSuccess(profiles)),
        catchError((err: AjaxError) => of(fetchProfilesError()))
      )
    )
  );
