import React from "react";
import { Route } from "react-router-dom";
import {
  AsyncHome,
  AsyncSearch,
  AsyncAudits,
  AsyncProfiles,
  AsyncPageNotFound
} from "./LoadableRoutes";

const getAppRoutes = () => {
  return [
    <Route exact path="/" component={AsyncHome} />,
    <Route path="/search" component={AsyncSearch} />,
    <Route path="/audits" component={AsyncAudits} />,
    <Route path="/profiles" component={AsyncProfiles} />,
    <Route component={AsyncPageNotFound} />
  ];
};

export default getAppRoutes;
