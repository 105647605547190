import {
  Profile,
  ProfilesState,
  ProfilesActionTypes,
  FETCH_PROFILES_SUCCESS
} from "./types";

export const initialState: ProfilesState = {
  allIds: [],
  byId: {}
};

export default function(
  state = initialState,
  action: ProfilesActionTypes
): ProfilesState {
  switch (action.type) {
    case FETCH_PROFILES_SUCCESS: {
      return {
        ...state,
        allIds: action.payload.map(x => x.id),
        byId: normalize(action.payload)
      };
    }
    default:
      return state;
  }
}

const normalize = (profiles: Profile[]): { [id: string]: Profile } =>
  profiles.reduce((acc, x) => ({ ...acc, [x.id]: x }), {});
