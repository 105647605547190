export const USER_SIGNED_IN = "USER_SIGNED_IN";

export interface UserState {
  userId: string | null;
  isSignedIn: boolean;
}

export type UserSignedInPayload = string;

export interface UserSignedInAction {
  type: typeof USER_SIGNED_IN;
  payload: UserSignedInPayload;
}

export type UserActionTypes = UserSignedInAction;
