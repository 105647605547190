interface Normalized<T> {
  allIds: number[];
  byId: { [id: string]: T };
}

export interface Profile {
  id: number;
  userId: string;
  customerId: string;
  name: string;
  createdAt: string;
  modifiedAt: string;
  documentsCount: number;
}

export type ProfilesState = Normalized<Profile>;

export const FETCH_PROFILES = "FETCH_PROFILES";
export const FETCH_PROFILES_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILES_ERROR = "FETCH_PROFILE_ERROR";

export interface FetchProfilesAction {
  type: typeof FETCH_PROFILES;
}

export interface FetchProfilesSuccessAction {
  type: typeof FETCH_PROFILES_SUCCESS;
  payload: Profile[];
}

export interface FetchProfilesErrorAction {
  type: typeof FETCH_PROFILES_ERROR;
}

export type ProfilesActionTypes =
  | FetchProfilesAction
  | FetchProfilesSuccessAction
  | FetchProfilesErrorAction;
