import { USER_SIGNED_IN, UserActionTypes, UserState } from "./types";

export const initialState: UserState = {
  userId: null,
  isSignedIn: false
};

export default function(
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case USER_SIGNED_IN: {
      return {
        ...state,
        userId: action.payload,
        isSignedIn: true
      };
    }
    default:
      return state;
  }
}
