import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Auth, { AuthenticationProvider } from "@creditsafe/upp-authentication";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { userSignedIn } from "./store/user/actions";

Auth.configure();
const store = configureStore();

const Root = () => (
  <Provider store={store}>
    <Router>
      <AuthenticationProvider
        onSignin={oidcUser =>
          store.dispatch(userSignedIn(oidcUser.profile.sub))
        }
      >
        <App />
      </AuthenticationProvider>
    </Router>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
