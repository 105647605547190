import React from "react";
import { Switch, Route } from "react-router-dom";
import AppLayout from "./containers/AppLayout";
import { useSelector } from "react-redux";
import { getIsSignedIn } from "./store/user/selectors";
import SignInLoading from "./pages/SignInLoading";

const App: React.FC = () => {
  const isSignedIn = useSelector(getIsSignedIn);

  if (!isSignedIn) {
    return <SignInLoading />;
  }

  return (
    <Switch>
      <Route component={AppLayout} />
    </Switch>
  );
};

export default App;
